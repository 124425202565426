<template>
  <div class="pa-9 pa-md-6 pa-lg-9">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <h3 class="page-title font-weight-bold mb-0">
            月間勤怠データのダウンロード
          </h3>
        </v-col>

        <v-col cols="12">
          <Table
            :headers="headers"
            :items="worktimeReportList"
            :footer="false"
            :disable-pagination="true"
            :total-records="worktimeReportList.length"
          >
            <template v-slot:item.date="{ item }">
              <span>{{ formatDate(item.date) }}</span>
            </template>

            <template v-slot:item.calculate="{ item }">
              <span>
                <v-btn
                  text
                  color="#4F55A7"
                  max-height="24"
                  @click="calculateWorktimeReport(item.id)"
                  :loading="isLoading(item.id)"
                >
                  <v-icon size="16" class="mr-2">
                    $setting
                  </v-icon>
                  計算する
                </v-btn>
              </span>
            </template>

            <template v-slot:item.calculated_at="{ item }">
              <span>{{ item.calculated_at }}</span>
            </template>

            <template v-slot:item.downloaded_at="{ item }">
              <span>{{ item.downloaded_at }}</span>
            </template>

            <template v-slot:item.user="{ item }">
              <span v-if="item.user">{{ item.user.full_name }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                class="primary--text"
                text
                v-if="item.calculated_at"
                @click="exportReport(item.id)"
              >
                ダウンロード
              </v-btn>
            </template>

            <template v-slot:top>
              <div class="mb-4">
                <v-row align="center">
                  <v-col cols="auto" class="text-heading-2 font-weight-medium">
                    過去6か月分のみ表示します。
                  </v-col>
                </v-row>
              </div>
            </template>
          </Table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Table from '@/components/admin/partials/Table/Table'
import dayjs from '@/plugins/dayjs'
import axios from 'axios'
export default {
  name: 'Monthly',
  components: { Table },
  created() {
    this.getDataFromApi()
  },
  data() {
    return {
      headers: [
        { text: '対象月', align: 'center', value: 'date' },
        { text: '', align: 'center', value: 'calculate', sortable: false },
        {
          text: '最終更新日',
          align: 'center',
          value: 'calculated_at',
          sortable: false
        },
        {
          text: '最終ダウンロード日時',
          align: 'center',
          value: 'downloaded_at',
          sortable: false
        },
        {
          text: '最終ダウンロードユーザー',
          align: 'center',
          value: 'user',
          sortable: false
        },
        {
          text: '',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ],
      loading: []
    }
  },
  computed: {
    worktimeReportList() {
      return this.$store.getters.MontlyWorktimes
    }
  },

  methods: {
    async getDataFromApi() {
      await this.$store.dispatch('MONTHLY_WORKTIME')
    },

    formatDate(value) {
      return dayjs(value).format('YYYY年MM月')
    },

    calculateWorktimeReport(id) {
      this.loading.push(id)
      axios
        .get('worktimes/monthly-report/calculate/' + id)
        .then(resp => {
          let report = this.worktimeReportList.find(wt => {
            return wt.id == resp.data.data.report.id
          })
          report.calculated_at = resp.data.data.report.calculated_at
        })
        .finally(() => {
          let index = this.loading.indexOf(id)
          if (index !== -1) this.loading.splice(index, 1)
        })
    },

    isLoading(id) {
      if (this.loading.includes(id)) return true
      else return false
    },

    exportReport(id) {
      axios.get('worktimes/monthly-report/touch-export/' + id).then(resp => {
        if (resp.status === 200) {
          let report = this.worktimeReportList.find(wt => {
            return wt.id == resp.data.data.report.id
          })
          report.user = resp.data.data.report.user
          report.downloaded_at = resp.data.data.report.downloaded_at
          axios
            .get('worktimes/monthly-report/export/' + id, {
              responseType: 'arraybuffer'
            })
            .then(response => {
              let file_name = ''
              let time = dayjs(report.downloaded_at).format(
                'YYYYMMDDHHmm'
              )
              let month = dayjs(report.date).format('M')
              file_name = month + '月の勤怠データ_' + time + '.xlsx'
              var fileURL = window.URL.createObjectURL(
                new Blob([response.data])
              )
              var fileLink = document.createElement('a')
              fileLink.href = fileURL
              fileLink.setAttribute('download', file_name)
              document.body.appendChild(fileLink)
              fileLink.click()
            })
        }
      })
    }
  }
}
</script>

<style src="./List.scss" lang="scss" scoped></style>
